<template>
	<!-- 产品 -需求-需求详情-->
<div>	
	<GModule class="container">
		<GTitle name="需求详情" style="margin:0 20px 20px;"></GTitle>
		<div class="detail-all" :data-jud="authority.front_hour">
			<template v-if="authority.add||authority.addCases||authority.createTask||authority.createTask||(details&&details.date&&(details.date.name!='原始需求')&&authority.edit_hour)">
				<Demand :details="details" class="pdf-module" noinfo="0" />
				<Demand :details="details" class="pdf-module" noinfo="1" />
			</template>
			<Demand v-else :details="details" class="pdf-module" noinfo="2" />









			<!-- <a-form
				class="need-box"
			    :label-col="{ span: 8}"
			    :wrapper-col="{ span: 10}"
			    autocomplete="off"
				:scrollToFirstError='true'>
				<a-form-item label="需求描述">
				    <a-textarea style="height: 150px;" :value="info.describe" disabled/>
				</a-form-item>
				<a-form-item label="参数/逻辑说明">
				    <a-textarea style="height: 90px;" :value="info.explain" disabled/>
				</a-form-item>
				<a-form-item label="需求图片">
				    <div class="img-scroll" @click="openSwiper(0)">
				    	<a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in info.imgArr" :key="ind"/>
				    </div>
				</a-form-item>
				<a-form-item label="需求类型">
				    {{need.name}}
				</a-form-item>
				<a-form-item label="需求模块">
				    <a-breadcrumb separator=">" style="width: 400px;margin-bottom: 14px;">
				        <a-breadcrumb-item 
				    		v-for="(item,index) in info.modulars" 
				    		:key="index">
				    		{{item}}
				    	</a-breadcrumb-item>
				    </a-breadcrumb>
				</a-form-item>
				<a-form-item label="需求ID">{{info.id}}</a-form-item>
				<a-form-item label="状态">{{info.status_text}}</a-form-item>
				<a-form-item label="创建时间">{{info.createtime_text}}</a-form-item>
				<a-form-item label="已完成页面图">
				    <template v-if="need.name == '原始需求'">
						<div class="overimg-box">
							<p>静态</p>
							<div class="overimg" @click="openSwiper(1)" v-if="static.length > 0">
								<a-image :preview="false" :width="65" :src="itm" v-for="(itm,ind) in static" :key="ind"/>
							</div>
							<p v-else>无</p>
						</div>
						<div class="overimg-box" style="margin-top: 10px;">
							<p>联调</p>
							<div class="overimg" @click="openSwiper(2)" v-if="union.length > 0">
								<a-image :preview="false" :width="65" :src="itm" v-for="(itm,ind) in union" :key="ind"/>
							</div>
							<p v-else>无</p>
						</div>
					</template>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:12 }" v-if="union.length > 0 || static.length > 0">
				  <a-button class="btn clo1" @click="openDownloadImgs()">下载</a-button>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:12 }" v-if="all.length > 0">
				  <a-button class="btn clo1" @click="openDownloadImgsOr()">下载</a-button>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10}">
				  <a-button class="btn clo6" @click="goBack()">返回</a-button>
				  <a-button class="btn clo3">修改</a-button>
				  <a-popconfirm
				  	title="确定删除此需求吗?"
				  	ok-text="确定"
				  	cancel-text="取消"
				  	@confirm="del(info.id)">
				  	<a-button class="btn" type="danger">删除</a-button>
				  </a-popconfirm>
				</a-form-item>
			</a-form> -->
		</div>
	</GModule>	
</div>	
</template>

<script>
import Demand from '@/components/PdDemand'; // Demand
// import Cases from '@/components/PdCases'; // Cases
// import Task from '@/components/PdTask'; // Task

	export default{
		name:'ProductDetail',
		data(){
			return{
				id:0 ,//详情id
				details:{} ,// 需求详情
				need:{} ,//需求类型
				all:[] ,// 已完成图片
				union:[] ,// 联调图片
				static:[] // 静态图片
				
			}
		},
	  components:{
	  	Demand,
	  	// Task,
	  	// Cases,
		},
		computed:{
			authority(){
			  var data = {};
			  this.$store.state.params.forEach(item=>{
				let name = item.name.split('/');
				if(name[1]=='demand'){
					data[name[2]] = true;
				}else if(name[1]=='demand_date'){
					data[name[2]+'Date'] = true;
				}else if(name[1]=='cases'){
					data[name[2]+'Cases'] = true;
				}else if(name[1]=='task'){
					data['createTask'] = true;
				}
			  })
			  return data
			},
		},
		created() {
			this.$store.commit('setStore', { name: 'hideSider', data: 1 })
			this.id = this.$route.query.id || 0;
			this.getDetails();
		},
		mounted() {
			// this.$store.commit('setStaticImgs',this.imgArr)
			// this.$store.commit('setAlignmentImgs',this.imgArr2)
		},
		methods:{
			async getDetails(){
				if(this.id){
					// 获取需求详情
					let res = await this.$utils.api.post({
						url:'/demand/getRow',
						data:{
							ids:this.id
						},
						result:1
					})
					if(res.code == 1){
						if(res.data.images.length > 0){
							res.data.imgArr = res.data.images.split(',')
						}
						this.details = res.data
						// this.need = res.data.date
						// this.all = res.data.complete_images.all
						// this.union = res.data.complete_images.union
						// this.static = res.data.complete_images.static
						this.$store.commit('setStore',{ name:'history', data:{list:res.data.history,type:'demand',id:res.data.id} })
					}
				}

			},




			openSwiper(index){
			  // 弹出图片列表
			  let arr = index == 1 ? this.static : index == 2 ? this.union : this.details.imgArr
			  this.$store.commit('setSwiperArr',arr)
			  this.$store.commit('setSwiper',1)
			},
			openDownloadImgs(){
				// 弹出图片下载(静态、联调)
				this.$store.commit('setStaticImgs',this.static)
				this.$store.commit('setAlignmentImgs',this.union)
				this.$store.commit('setImgCheck',1)
			},
			openDownloadImgsOr(){
				// 弹出图片下载（新增更改需求）
				this.$store.commit('setImgOther',1)
			},
			async del(id){
				// 删除
				let res = await this.$utils.api.post({
					url:'/demand/del',
					data:{
						ids:id
					},
					  result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('删除成功')
					this.goBack()
				}
			},
			goBack(){
				// 返回
	      this.$store.commit('setStore',{ name:'hideSider', data:0 })
	      this.$store.commit('setStore',{ name:'history', data:null })
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0 0 20px;
	.pdf-module{
		flex:1;
	}
	.detail-all{
		padding: 30px 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		.pdf-module{
			flex:1;
		}
		.need-box{
			.btn{
				margin-right: 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;

					}
				}
			}
			.overimg-box{
				width: 450px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				p{
					width: 25px;
					margin: 0;
					padding: 0;
					font-size: 12px;
				}
				.overimg{
					display: flex;
					width: 370px;
					height: 60px;
					overflow: hidden;
				}
			}
		}
	}
}
</style>